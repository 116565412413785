import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  Hidden,
  TextField,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import {
  openFellowDialog,
  closeFellowDialog,
  requestCreateFellow,
} from '../../redux/fellow'
import { isValid, isValidUser } from './helper'

const initialState = {
  firstName: '',
  lastName: '',
  personalNumber: '',
  email: '',
  phone: '',
  street: '',
  zip: '',
  city: '',
}

const initialTouched = {
  firstName: false,
  lastName: false,
  personalNumber: false,
  email: false,
  phone: false,
  street: false,
  zip: false,
  city: false,
}

const AddFellow = ({
  auth,
  onSubmit,
  isFetching,
  openDialog,
  onOpenDialog,
  onCloseDialog,
}) => {
  const { t } = useTranslation()
  const [state, setState] = useState(initialState)
  const [touched, setTouched] = useState(initialTouched)

  useEffect(() => {
    setState(initialState)
    setTouched(initialTouched)
  }, [openDialog])

  function handleChange(event) {
    const { name, value } = event.target
    setState({ ...state, [name]: value })
  }

  function handleBlur(event) {
    const { name } = event.target
    setTouched({ ...touched, [name]: true })
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (isValidUser(state)) {
      if (!isFetching) {
        onSubmit(auth, state)
      }
    } else {
      setTouched({
        firstName: true,
        lastName: true,
        personalNumber: true,
        email: true,
        phone: true,
        street: true,
        zip: true,
        city: true,
      })
    }
  }

  const {
    firstName,
    lastName,
    personalNumber,
    email,
    phone,
    street,
    zip,
    city,
  } = state

  return (
    <Grid2 size={12}>
      <Button
        onClick={onOpenDialog}
        style={{ textAlign: 'left', marginRight: 32 }}
        endIcon={<Add />}
      >
        {t('Add co-applicant')}
      </Button>
      <Dialog
        component="form"
        onSubmit={handleSubmit}
        open={openDialog}
        onClose={onCloseDialog}
      >
        <DialogTitle>
          {t('Enter contact information for your co-applicant')}
        </DialogTitle>
        <DialogContent>
          <Grid2 container spacing={1}>
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('First name')}
                name="firstName"
                autoComplete="given-name"
                value={firstName}
                error={touched.firstName && !isValid({ firstName })}
                helperText={
                  touched.firstName &&
                  !isValid({ firstName }) &&
                  t('Enter first name')
                }
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('Last name')}
                name="lastName"
                autoComplete="family-name"
                value={lastName}
                error={touched.lastName && !isValid({ lastName })}
                helperText={
                  touched.lastName &&
                  !isValid({ lastName }) &&
                  t('Enter last name')
                }
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('Personal number')}
                placeholder={t('yyyymmddnnnn')}
                name="personalNumber"
                type="tel"
                value={personalNumber}
                error={touched.personalNumber && !isValid({ personalNumber })}
                helperText={
                  touched.personalNumber &&
                  !isValid({ personalNumber }) &&
                  t('Enter social security number (yyyymmddnnnn)')
                }
              />
            </Grid2>
            <Grid2
              implementation="css"
              smDown
              component={Hidden}
              size={{
                sm: 6,
              }}
            />
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('Email address')}
                name="email"
                type="email"
                value={email}
                error={touched.email && !isValid({ email })}
                helperText={
                  touched.email &&
                  !isValid({ email }) &&
                  t('Enter email address')
                }
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('Phone number')}
                name="phone"
                type="tel"
                value={phone}
                error={touched.phone && !isValid({ phone })}
                helperText={
                  touched.phone &&
                  !isValid({ phone }) &&
                  t('Enter phone number')
                }
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 5,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('Street address')}
                name="street"
                value={street}
                error={touched.street && !isValid({ street })}
                helperText={
                  touched.street &&
                  !isValid({ street }) &&
                  t('Enter street address')
                }
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 3,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('Postal code')}
                name="zip"
                type="tel"
                value={zip}
                error={touched.zip && !isValid({ zip })}
                helperText={
                  touched.zip && !isValid({ zip }) && t('Enter postal code')
                }
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('City')}
                name="city"
                value={city}
                error={touched.city && !isValid({ city })}
                helperText={
                  touched.city && !isValid({ city }) && t('Enter city')
                }
              />
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDialog}>{t('Cancel')}</Button>
          <Button loading={isFetching} type="submit" onClick={handleSubmit}>
            {t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid2>
  )
}
const mapStateToProps = ({ auth, fellow }) => {
  const { openDialog, isFetching } = fellow
  return { auth, isFetching, openDialog }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: (auth, fellow) => dispatch(requestCreateFellow(auth, fellow)),
    onOpenDialog: () => dispatch(openFellowDialog()),
    onCloseDialog: () => dispatch(closeFellowDialog()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddFellow)

import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import Chip from './chip'
import { getLocation, getReferrer, formatMoney, formatArea } from './utils'

const SignupDialog = ({
  fields,
  isOrg,
  onClose,
  onError,
  onSuccess,
  open,
  property,
  token,
}) => {
  const { t } = useTranslation()
  const { projectId } = useParams()

  const [organizationName, setOrganizationName] = useState('')
  const [organizationNumber, setOrganizationNumber] = useState('')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [birthYear, setBirthYear] = useState('')

  const [organizationNameError, setOrganizationNameError] = useState(false)
  const [organizationNumberError, setOrganizationNumberError] = useState(false)
  const [fullNameError, setFullNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)
  const [postalCodeError, setPostalCodeError] = useState(false)
  const [birthYearError, setBirthYearError] = useState(false)

  const [loading, setLoading] = useState(false)

  const resetState = () => {
    setOrganizationName('')
    setOrganizationNumber('')
    setFullName('')
    setEmail('')
    setPhone('')
    setPostalCode('')
    setBirthYear('')

    setOrganizationNameError(false)
    setOrganizationNumberError(false)
    setFullNameError(false)
    setEmailError(false)
    setPhoneError(false)
    setPostalCodeError(false)
    setBirthYearError(false)

    setLoading(false)
  }

  const handleClose = () => {
    resetState()
    onClose()
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault()

    if (loading) return

    setLoading(true)

    const user = {
      fullName,
      email,
      phone,
      zip: postalCode,
      birthYear,
      projectId,
      propertyId: property.id,
      location: getLocation(document),
      referrer: getReferrer(document),
    }

    if (isOrg) {
      user.organizationName = organizationName
      user.organizationNumber = organizationNumber
    }

    try {
      const response = await fetch('/api/v1/interest-signups', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(user),
      })

      setLoading(false)

      if (response.status >= 500) {
        throw new Error(`HTTP status ${response.status}`)
      }

      const data = await response.json()

      if (data?.message?.organizationName) {
        setOrganizationNameError(true)
      }
      if (data?.message?.organizationNumber) {
        setOrganizationNumberError(true)
      }
      if (data?.message?.fullName) {
        setFullNameError(true)
      }
      if (data?.message?.email) {
        setEmailError(true)
      }
      if (data?.message?.phone) {
        setPhoneError(true)
      }
      if (data?.message?.postalCode) {
        setPostalCodeError(true)
      }
      if (data?.message?.birthYear) {
        setBirthYearError(true)
      }

      if (data?.fullName) {
        resetState()
        onSuccess()
      }
    } catch (error) {
      // Network error or json parse error
      console.log(error)
      onError(error)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      component="form"
      onSubmit={handleSubmit}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          {t('Interest for {{n}}', { n: property?.number })}
          <Chip status={property?.status} />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Stack
                      direction="row"
                      spacing={1}
                      divider={<Divider orientation="vertical" flexItem />}
                    >
                      {fields?.includes('BUILDING') && (
                        <Typography>{property?.building}</Typography>
                      )}
                      {fields?.includes('ROOMS') && (
                        <Typography>
                          {t('{{n}} rooms', { n: property?.rooms })}
                        </Typography>
                      )}
                      {fields?.includes('AREA') && (
                        <Typography>{formatArea(property?.area)}</Typography>
                      )}
                      {fields?.includes('FLOOR') && (
                        <Typography>
                          {t('floor {{n}}', { n: property?.floor })}
                        </Typography>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
                {fields?.includes('FLOOR_PLAN_URL') && (
                  <TableRow>
                    <TableCell>
                      <Typography>{t('Floor plan')}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      {property?.floorPlanUrl && (
                        <IconButton
                          size="small"
                          aria-label="download"
                          href={property?.floorPlanUrl}
                          target="_blank"
                        >
                          <DownloadIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                )}
                {fields?.includes('PRICE') && (
                  <TableRow>
                    <TableCell>
                      <Typography>{t('Price')}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>{formatMoney(property?.price)}</Typography>
                    </TableCell>
                  </TableRow>
                )}
                {fields?.includes('MONTHLY_FEE') && (
                  <TableRow>
                    <TableCell>
                      <Typography>{t('Monthly fee')}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography>
                        {formatMoney(property?.monthlyFee)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Grid2 container spacing={1}>
            {isOrg && (
              <>
                <Grid2
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    label={t('Organization name')}
                    name="organization"
                    onChange={(ev) => {
                      setOrganizationName(ev.target.value)
                    }}
                    value={organizationName}
                    helperText={
                      organizationNameError && t('Enter organization name')
                    }
                  />
                </Grid2>
                <Grid2
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    label={t('Organization number')}
                    name="organization-number"
                    onChange={(ev) => {
                      setOrganizationNumber(ev.target.value)
                    }}
                    value={organizationNumber}
                    helperText={
                      organizationNumberError && t('Enter organization number')
                    }
                  />
                </Grid2>
              </>
            )}
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                fullWidth
                label={t('Name')}
                name="name"
                onChange={(ev) => {
                  setFullName(ev.target.value)
                }}
                value={fullName}
                helperText={fullNameError && t('Enter name')}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                fullWidth
                label={t('Email address')}
                name="email"
                onChange={(ev) => {
                  setEmail(ev.target.value)
                }}
                value={email}
                helperText={emailError && t('Enter email address')}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <TextField
                fullWidth
                label={t('Phone number')}
                name="tel"
                onChange={(ev) => {
                  setPhone(ev.target.value)
                }}
                value={phone}
                helperText={phoneError && t('Enter phone number')}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <TextField
                fullWidth
                label={t('Postal code')}
                name="postal-code"
                onChange={(ev) => {
                  setPostalCode(ev.target.value)
                }}
                value={postalCode}
                helperText={postalCodeError && t('Enter postal code')}
              />
            </Grid2>
            {!isOrg && (
              <Grid2
                size={{
                  xs: 12,
                  sm: 4,
                }}
              >
                <TextField
                  fullWidth
                  label={t('Year of birth')}
                  name="bday-year"
                  onChange={(ev) => {
                    setBirthYear(ev.target.value)
                  }}
                  value={birthYear}
                  helperText={birthYearError && t('Enter year of birth (yyyy)')}
                />
              </Grid2>
            )}
          </Grid2>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Cancel')}</Button>
        <Button
          loading={loading}
          variant="contained"
          type="submit"
          onClick={handleSubmit}
        >
          <span>{t('Send')}</span>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SignupDialog

import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid2,
  Hidden,
  TextField,
  RadioGroup,
  Radio,
  Stack,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { requestUpdateSalesStartSignup } from '../../redux/user'
import PrivacyPolicy from '../privacyPolicy'
import Html from '../html'
import EmailField from '../EmailField'
import AddFellow from './addFellow'
import Fellows from './fellows'
import {
  getWindowLocationHref,
  getDocumentReferrer,
  isValid,
  isValidUser,
  isValidCompany,
} from './helper'

function SalesStartSignupFormComponent() {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const project = useSelector((state) => state?.project)
  const auth = useSelector((state) => state?.auth)
  const facebookPixelId = useSelector(
    (state) => state?.project?.facebookPixelId
  )
  const loading = useSelector((state) => state?.request?.updateSalesStartSignup)
  const user = useSelector((state) => state?.user)

  const [state, setState] = useState(user || {})
  const [location, setLocation] = useState(undefined)
  const [referrer, setReferrer] = useState(undefined)
  const [touched, setTouched] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setLocation(getWindowLocationHref())
  }, [])

  useEffect(() => {
    setReferrer(getDocumentReferrer())
  }, [])

  const defaultBuyer = project?.allowedBuyers?.[0] || 'PRIVATE'

  const [isOrganization, setOrganization] = useState(defaultBuyer !== 'PRIVATE')

  const getMembership = () => {
    if (state.membership) {
      return 'membership'
    } else if (state.secondMembership) {
      return 'secondMembership'
    } else if (state.thirdMembership) {
      return 'thirdMembership'
    }
    return ''
  }

  const setMembership = (val) => {
    setState({
      ...state,
      membership: val === 'membership',
      secondMembership: val === 'secondMembership',
      thirdMembership: val === 'thirdMembership',
    })
  }

  function handleChange(event) {
    const { name, value } = event.target
    setState({ ...state, [name]: value })
  }

  function handleCheck(event) {
    const { name, checked } = event.target
    setState({ ...state, [name]: checked })
  }

  function handleBlur(event) {
    const { name } = event.target
    setTouched({ ...touched, [name]: true })
  }

  function handleCompanySubmit(event) {
    event.preventDefault()

    if (loading) return

    const checkMembership = project?.threeMemberships
      ? getMembership() !== ''
      : true

    if (
      !isValidCompany(state) ||
      !isValid({ acceptPrivacyPolicy: state.acceptPrivacyPolicy }) ||
      !checkMembership
    ) {
      setTouched({
        firstName: true,
        lastName: true,
        organizationNumber: true,
        organizationName: true,
        email: true,
        phone: true,
        street: true,
        zip: true,
        city: true,
        acceptPrivacyPolicy: true,
        membership: true,
      })
      return
    }

    dispatch(
      requestUpdateSalesStartSignup(auth, { ...state, location, referrer })
    )
  }

  function handleUserSubmit(event) {
    event.preventDefault()

    if (loading) return

    const checkMembership = project?.threeMemberships
      ? getMembership() !== ''
      : true

    if (
      !isValidUser(state) ||
      !isValid({ acceptPrivacyPolicy: state.acceptPrivacyPolicy }) ||
      !checkMembership
    ) {
      setTouched({
        firstName: true,
        lastName: true,
        personalNumber: true,
        email: true,
        phone: true,
        street: true,
        zip: true,
        city: true,
        acceptPrivacyPolicy: true,
        membership: true,
      })
      return
    }

    dispatch(
      requestUpdateSalesStartSignup(auth, { ...state, location, referrer })
    )
  }

  return (
    <Stack spacing={3}>
      <Html name="personal-details" />
      <Stack
        spacing={3}
        component="form"
        onSubmit={isOrganization ? handleCompanySubmit : handleUserSubmit}
      >
        <Stack spacing={1}>
          {project?.allowedBuyers?.length > 1 && (
            <FormControlLabel
              label={
                defaultBuyer === 'PRIVATE'
                  ? t('I represent an organization')
                  : t("I'm an individual")
              }
              value={
                defaultBuyer === 'PRIVATE' ? isOrganization : !isOrganization
              }
              onChange={(e) =>
                setOrganization(
                  defaultBuyer === 'PRIVATE'
                    ? e.target.checked
                    : !e.target.checked
                )
              }
              control={
                <Checkbox
                  checked={
                    defaultBuyer === 'PRIVATE'
                      ? isOrganization
                      : !isOrganization
                  }
                />
              }
            />
          )}
          <Grid2 container spacing={1}>
            {isOrganization && (
              <>
                <Grid2
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={t('Organization name')}
                    name="organizationName"
                    autoComplete="organization"
                    value={state?.organizationName || ''}
                    error={
                      touched.organizationName &&
                      !isValid({ organizationName: state?.organizationName })
                    }
                    helperText={
                      touched.organizationName &&
                      !isValid({ organizationName: state?.organizationName }) &&
                      t('Enter organization name')
                    }
                  />
                </Grid2>
                <Grid2
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={t('Organization number')}
                    name="organizationNumber"
                    value={state?.organizationNumber || ''}
                    error={
                      touched.organizationNumber &&
                      !isValid({
                        organizationNumber: state?.organizationNumber,
                      })
                    }
                    helperText={
                      touched.organizationNumber &&
                      !isValid({
                        organizationNumber: state?.organizationNumber,
                      }) &&
                      t('Enter organization number')
                    }
                  />
                </Grid2>
              </>
            )}
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={
                  isOrganization
                    ? t('Contact person first name')
                    : t('First name')
                }
                name="firstName"
                autoComplete="given-name"
                value={state?.firstName || ''}
                error={
                  touched.firstName && !isValid({ firstName: state?.firstName })
                }
                helperText={
                  touched.firstName &&
                  !isValid({ firstName: state?.firstName }) &&
                  t('Enter first name')
                }
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={
                  isOrganization
                    ? t('Contact person last name')
                    : t('Last name')
                }
                name="lastName"
                autoComplete="family-name"
                value={state?.lastName || ''}
                error={
                  touched.lastName && !isValid({ lastName: state?.lastName })
                }
                helperText={
                  touched.lastName &&
                  !isValid({ lastName: state?.lastName }) &&
                  t('Enter last name')
                }
              />
            </Grid2>
            {!isOrganization && (
              <>
                <Grid2
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label={t('Personal number')}
                    placeholder={t('yyyymmddnnnn')}
                    name="personalNumber"
                    type="tel"
                    value={state?.personalNumber || ''}
                    error={
                      touched.personalNumber &&
                      !isValid({ personalNumber: state?.personalNumber })
                    }
                    helperText={
                      touched.personalNumber &&
                      !isValid({ personalNumber: state?.personalNumber }) &&
                      t('Enter social security number (yyyymmddnnnn)')
                    }
                  />
                </Grid2>
                <Grid2
                  implementation="css"
                  smDown
                  component={Hidden}
                  size={{
                    sm: 6,
                  }}
                />
              </>
            )}
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <EmailField
                email={state?.email || ''}
                touched={touched.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('Phone number')}
                name="phone"
                type="tel"
                value={state?.phone || ''}
                error={touched.phone && !isValid({ phone: state?.phone })}
                helperText={
                  touched.phone &&
                  !isValid({ phone: state?.phone }) &&
                  t('Enter phone number')
                }
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 5,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('Street address')}
                name="street"
                value={state?.street || ''}
                error={touched.street && !isValid({ street: state?.street })}
                helperText={
                  touched.street &&
                  !isValid({ street: state?.street }) &&
                  t('Enter street address')
                }
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 3,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('Postal code')}
                name="zip"
                type="tel"
                value={state?.zip || ''}
                error={touched.zip && !isValid({ zip: state?.zip })}
                helperText={
                  touched.zip &&
                  !isValid({ zip: state?.zip }) &&
                  t('Enter postal code')
                }
              />
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('City')}
                name="city"
                value={state?.city || ''}
                error={touched.city && !isValid({ city: state?.city })}
                helperText={
                  touched.city &&
                  !isValid({ city: state?.city }) &&
                  t('Enter city')
                }
              />
            </Grid2>
          </Grid2>
        </Stack>
        {project?.allowFellows && (
          <>
            <AddFellow />
            <Fellows />
          </>
        )}
        <Stack spacing={1}>
          {project?.membership && project?.threeMemberships && (
            <RadioGroup
              name="membership1"
              value={getMembership()}
              onChange={(e) => setMembership(e.target.value)}
            >
              <FormControlLabel
                value="membership"
                control={<Radio />}
                label={<Html name="membership" />}
              />
              <FormControlLabel
                value="secondMembership"
                control={<Radio />}
                label={<Html name="second-membership" />}
              />
              <FormControlLabel
                value="thirdMembership"
                control={<Radio />}
                label={<Html name="third-membership" />}
              />
              {touched.membership && getMembership() === '' && (
                <FormHelperText error={true}>
                  {t('Please fill in the membership options')}
                </FormHelperText>
              )}
            </RadioGroup>
          )}
          {project?.membership && !project?.threeMemberships && (
            <FormControlLabel
              name="membership"
              control={
                <Checkbox
                  name="membership"
                  checked={state?.membership}
                  onChange={handleCheck}
                />
              }
              label={<Html name="membership" />}
            />
          )}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2, backgroundColor: grey[100] }}
          >
            <Stack spacing={1}>
              {facebookPixelId && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acceptTracking"
                      checked={state?.acceptTracking}
                      onChange={handleCheck}
                    />
                  }
                  label={t(
                    'I accept tracking for marketing purposes (optional)'
                  )}
                />
              )}
              <FormControl
                required
                error={
                  touched.acceptPrivacyPolicy &&
                  !isValid({ acceptPrivacyPolicy: state?.acceptPrivacyPolicy })
                }
                component="fieldset"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acceptPrivacyPolicy"
                      checked={state?.acceptPrivacyPolicy}
                      onChange={handleCheck}
                      onBlur={handleBlur}
                    />
                  }
                  label={t(
                    'I have read the information about how my personal data is processed'
                  )}
                />
                {touched.acceptPrivacyPolicy &&
                  !isValid({
                    acceptPrivacyPolicy: state?.acceptPrivacyPolicy,
                  }) && (
                    <FormHelperText>
                      {t('Please accept our personal data policy')}
                    </FormHelperText>
                  )}
              </FormControl>
            </Stack>
            <PrivacyPolicy />
          </Stack>
        </Stack>
        <Stack spacing={3} direction="row" alignItems="center">
          <Button
            variant="contained"
            type="submit"
            onSubmit={isOrganization ? handleCompanySubmit : handleUserSubmit}
          >
            {t('Continue')}
          </Button>
          {loading && <CircularProgress size={20} />}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default SalesStartSignupFormComponent

import { useState } from 'react'
import {
  Hidden,
  Button,
  CircularProgress,
  Grid2,
  TextField,
} from '@mui/material'
import Html from '../html'
import { useTranslation } from 'react-i18next'

const SignupForm = ({ isFetching, onSubmit }) => {
  const { t } = useTranslation()
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    attendeeCount: '',
  })

  function handleChange(event) {
    const { name, value } = event.target
    setState({ ...state, [name]: value })
  }

  function handleSubmit(e) {
    e.preventDefault()
    onSubmit(state)
  }

  const { firstName, lastName, email, phone, attendeeCount } = state

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(state)
      }}
    >
      <Grid2 container spacing={3}>
        <Grid2 size={12}>
          <Html name="event-signup-intro" data={state} />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            label={t('First name')}
            name="firstName"
            autoComplete="given-name"
            value={firstName}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            label={t('Last name')}
            name="lastName"
            autoComplete="family-name"
            value={lastName}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            label={t('Email address')}
            name="email"
            type="email"
            value={email}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            label={t('Phone number')}
            name="phone"
            type="tel"
            value={phone}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 4,
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            label={t('Number of attendees')}
            name="attendeeCount"
            type="tel"
            value={attendeeCount}
            onChange={handleChange}
          />
        </Grid2>
        <Grid2
          implementation="css"
          smDown
          component={Hidden}
          size={{
            sm: 6,
          }}
        />
        <Grid2
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <Button
            variant="contained"
            type="submit"
            onSubmit={handleSubmit}
            style={{ marginTop: 32, width: 90 }}
          >
            {isFetching && <CircularProgress color="inherit" size={24} />}
            {!isFetching && <span>{t('Send')}</span>}
          </Button>
        </Grid2>
      </Grid2>
    </form>
  )
}

export default SignupForm

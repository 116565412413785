import { connect } from 'react-redux'
import { Chip, Grid2 } from '@mui/material'
import { Face } from '@mui/icons-material'
import { requestDeleteFellow } from '../../redux/fellow'

const Fellows = ({ auth, fellows, isFetching, onDelete }) => {
  function handleDelete(fellow) {
    if (isFetching) return
    onDelete(auth, fellow)
  }

  if (!fellows.length) return null

  return (
    <Grid2 size={12}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {fellows.map((f) => {
          return (
            <div key={f.id} style={{ marginRight: 8, marginBottom: 8 }}>
              <Chip
                icon={<Face />}
                label={`${f.firstName} ${f.lastName}`}
                onDelete={() => handleDelete(f)}
              />
            </div>
          )
        })}
      </div>
    </Grid2>
  )
}

const mapStateToProps = ({ auth, fellow }) => {
  const { isFetching, items } = fellow
  return { auth, fellows: items || [], isFetching }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onDelete: (auth, fellow) => {
      dispatch(requestDeleteFellow(auth, fellow))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Fellows)
